import React from 'react';
import Fade from 'react-reveal/Fade';

import Navbar from '../components/Navbar';
import '../work.css';

function Work() {
    return (
        <main id="work"> 
            <Navbar />
            hi
        </main>
    )
}

export default Work; 